<template>
  <Dialog :header="slotData ? 'Edit Slot' : 'Create Slot'" v-model:visible="showModal"
  :modal="true" :closable="false" :style="{width: '65vw'}">
      <div class="container-fluid mb-4">
          <div class="row">
            <div v-bind:class="slotData ? 'col-md-8' : 'col-md-12'">
                <label for="slotName" class="fw-bold">Name<span class="text-danger ms-1">*</span></label>
                <input
                    id="slotName"
                    type="text"
                    v-model.lazy="v$.slotName.$model"
                    class="w-100 form-control p-2"
                    :disabled="slotData || slotCount > minSlotAndTeamMemberCount"
                    placeholder="Name"
                />
                <span
                    v-if="v$.slotName.$error && (v$.slotName.required && v$.slotName.required.$invalid)"
                    class="text-danger">{{messages.slotValidation.slotNameErrorMessage}}
                </span>
                <span
                    v-else-if="v$.slotName.$error && (v$.slotName.maxLength && v$.slotName.maxLength.$invalid)"
                    class="text-danger">{{messages.slotValidation.slotNameCharacterLimit}}
                </span>
                <span
                    v-else-if="v$.slotName.$error && (v$.slotName.asyncValidator && v$.slotName.asyncValidator.$invalid) && slotName"
                    class="text-danger">{{messages.slotValidation.slotNameAlreadyExist}}
                </span>
            </div>
            <div class="col-md-4" v-if="slotData">
                <label class="fw-bold">Status</label>
                <div>
                    <RadioButton id="active" name="Active" :value="slotState.Active" v-model="form.state"
                    :disabled="slotData && slotData.booking"/>
                    <label for="active" class="fw-bold ms-2 me-5">Active</label>
                    <RadioButton id="inactive" name="In-Active" :value="slotState.InActive" v-model="form.state"
                    :disabled="slotData && slotData.booking"/>
                    <label for="inactive" class="fw-bold ms-2">In-active</label>
                </div>
            </div>
          </div>
          <div class="row mt-4">
              <div :class="slotData ? 'col-md-4' : 'col-md-3'">
                <label for="from" class="fw-bold">From<span class="text-danger ms-1">*</span></label>
                <Calendar
                    id="from"
                    v-model="v$.startDate.$model"
                    :minDate="minDate"
                    :showIcon="true"
                    :showButtonBar="true" class="w-100"
                    placeholder="Start Date"
                    :manualInput="false"
                    :disabledDays="[0, 6]"
                    :disabled="slotData"
                    @date-select="getDuration()"
                />
                <span
                    v-if="v$.startDate.$error && (v$.startDate.required && v$.startDate.required.$invalid)"
                    class="text-danger">{{messages.dateValidations.startDateRequired}}
                </span>
              </div>
              <div :class="slotData ? 'col-md-4' : 'col-md-3'">
                <label id="upto" class="fw-bold">Upto<span class="text-danger ms-1">*</span></label>
                <Calendar
                    for="upto"
                    v-model="v$.endDate.$model"
                    :minDate="minDate"
                    :showIcon="true"
                    :showButtonBar="true"
                    placeholder="End Date"
                    :manualInput="false"
                    :disabledDays="[0, 6]"
                    :disabled="slotData" class="w-100"
                    @date-select="getDuration()"
                />
                <span
                    v-if="v$.endDate.$error && (v$.endDate.required && v$.endDate.required.$invalid)"
                    class="text-danger">{{messages.dateValidations.endDateRequired}}
                </span>
                <span
                    v-else-if="v$.endDate.$error && (v$.endDate.minDate && v$.endDate.minDate.$invalid)"
                    class="text-danger">{{messages.dateValidations.endDateValidation}}
                </span>
              </div>
              <div class="col-md-3">
                  <label for="duration" class="fw-bold">Duration (Days)<span class="text-danger ms-1">*</span></label>
                  <InputText type="number" id="duration" v-model="v$.form.duration.$model" @paste.prevent
                    @keydown="Utility.preventCharactersInNumberField" :disabled="slotData"  :key="updateDuration"
                    class="w-100" placeholder="Duration" @change="updateSlotTitle(true)" min="1" />
                  <span
                    v-if="v$.form.duration.$error && (v$.form.duration.required &&
                    v$.form.duration.required.$invalid)"
                    class="text-danger">{{messages.slotValidation.durationRequired}}
                </span>
                <span
                    v-else-if="startDate && endDate && v$.form.duration.$error && (v$.form.duration.betweenValue &&
                    v$.form.duration.betweenValue.$invalid)" class="text-danger">
                    {{days == 1 ? messages.slotValidation.startDateEqualEndDate :
                    (days != null ? `${messages.slotValidation.durationRangeError}${days}` : '')}}
                </span>
              </div>
              <div class="col-md-3" :class="slotData ? 'mt-4' : ''">
                  <label for="memberCount" class="fw-bold">Team (Members)<span class="text-danger ms-1">*</span></label>
                  <InputText type="number" id="memberCount" v-model="v$.form.memberCount.$model" @paste.prevent
                  @keydown="Utility.preventCharactersInNumberField" :disabled="slotData" class="w-100" placeholder="Members" min="1"/>
                  <span
                    v-if="v$.form.memberCount.$error && (v$.form.memberCount.required && v$.form.memberCount.required.$invalid)"
                    class="text-danger">{{messages.slotValidation.teamMemberRequired}}
                  </span>
                  <span
                    v-else-if="v$.form.memberCount.$error && (v$.form.memberCount.minMemberCount &&
                    v$.form.memberCount.minMemberCount.$invalid)" class="text-danger">
                    {{messages.slotValidation.minTeamMember}}
                  </span>
              </div>
              <div class="row  mt-4" v-if="!slotData">
                <div class="col-md-3">
                  <label for="duration" class="fw-bold">No. of Slots</label>
                  <InputText type="number" id="noOfSlot" v-model="v$.slotCount.$model" @keydown="Utility.preventCharactersInNumberField"
                  :disabled="slotData" class="w-100" placeholder="No.of Slots" @change="updateSlotTitle(false)" min="1"  @paste.prevent/>
                  <span
                    v-if="v$.slotCount.$error && (v$.slotCount.required && v$.slotCount.required.$invalid)"
                    class="text-danger">{{messages.slotValidation.slotCountRequired}}
                  </span>
                  <span
                    v-else-if="v$.slotCount.$error && (v$.slotCount.minSlotCount && v$.slotCount.minSlotCount.$invalid)"
                    class="text-danger">{{messages.slotValidation.minSlotCount}}
                  </span>
                </div>
              </div>
          </div>
      </div>
      <template #footer class="mt-5">
        <hr>
        <Button label="DELETE" @click="deleteSlot(this.slotData.id)" class="p-button-danger float-start"
        :disabled="slotData && slotData.booking" v-if="this.slotData && isNavigationAllowed(NAVIGATIONCONST.DELETESLOT)"/>
        <Button label="SAVE" @click="createSlot" :disabled="slotData && slotData.booking ||
        disableSaveBtnOnceClicked" v-if="isNavigationAllowed(NAVIGATIONCONST.UPDATESLOT) || !slotData" />
        <Button label="CANCEL" @click="$emit('closeModal')" class="p-button-outlined me-3"/>
      </template>
  </Dialog>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { maxLength, minValue, required, helpers, between } from '@vuelidate/validators';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import RadioButton from 'primevue/radiobutton';
import { toasterTime, messages, minSlotAndTeamMemberCount, userActivities, toasterType } from '../../../../shared/constants/constants';
import { SlotState } from '../../../../shared/enums/slot-state';
import Utility from '../../../../shared/utility/utility';
import { NAVIGATIONCONST } from '../../../../shared/constants/navigation-constant';
import { isNavigationAllowed } from '../../../../shared/utils/auth-utils';
import { SlotService } from '../../../../apis';
import InputText from 'primevue/inputtext';
import { UserActivity } from '../../../../shared/utils';
const { withAsync } = helpers;
export default {
    setup: () => ({ v$: useVuelidate() }),
    name: 'CreateSlotForm',
    components: {
        Dialog,
        Button,
        Calendar,
        RadioButton,
        InputText
    },
    props: {
        slotData: Object,
        slots: Object
    },
    data() {
        return {
            userActivities: userActivities,
            form: {
                id: this.slotData ? this.slotData.id : '',
                duration: this.slotData ? this.slotData.schedule.days : null,
                state: this.slotData ? this.slotData.state : SlotState.Active,
                memberCount: this.slotData ? this.slotData.memberCount : minSlotAndTeamMemberCount
            },
            slotCount: minSlotAndTeamMemberCount,
            slotName: this.slotData ? this.slotData.title : '',
            startDate: this.slotData ? this.slotData.schedule.start : '',
            endDate: this.slotData ? this.slotData.schedule.end : '',
            showModal: true,
            minDate: new Date(),
            slotNameErrorMessage: null,
            slotState: SlotState,
            duration: null,
            disableSaveBtnOnceClicked: false,
            isNavigationAllowed,
            NAVIGATIONCONST,
            slotNames: [],
            messages,
            days: this.slotData ? this.slotData.schedule.days : null,
            minSlotAndTeamMemberCount,
            userEmail: null,
            Utility
        };
    },
    validations() {
        return {
            slotName: { required, maxLength: maxLength(100), asyncValidator: withAsync(this.checkSlotNameExisit) },
            startDate: { required },
            endDate: { required, minDate: minValue(this.startDate) },
            form: {
                duration: { required, betweenValue: between(1, this.days) },
                memberCount: { required, minMemberCount: minValue(1) }
            },
            slotCount: { required, minSlotCount: minValue(1) }
        };
    },
    methods: {
        showToaster(message, type) {
            this.$toast.add({
                severity: type,
                closable: false,
                detail: message,
                life: toasterTime
            });
        },
        getDateTemplate(start, end) {
            let format;
            if (Utility.formateDate(start, 'YY') == Utility.formateDate(end, 'YY')) {
                format = 'M/D';
            }
            return `${ Utility.formateDate(start, format) } - ${ Utility.formateDate(end, format) }`;
        },
        updateSlotTitle(duration) {
            if (duration && this.slotCount == 1) {
                return;
            }
            if (this.slotCount > 1 && this.startDate && this.endDate) {
                const res = this.getDateTemplate(new Date(this.startDate).getTime()/1000, new Date(this.endDate).getTime()/1000);
                this.slotName = `${ res } - ${ this.form.duration } day${ this.form.duration == 1 ? '' : 's' }`;
            } else {
                this.slotName = '';
            }
        },
        getDuration() {
            if (this.startDate && this.endDate && this.startDate <= this.endDate) {
                this.form.duration = Utility.getDuration(this.startDate.getTime()/1000, this.endDate.getTime()/1000);
                this.days = this.form.duration;
                this.updateSlotTitle(true);
            }
        },
        async checkSlotNameExisit() {
            if (this.slotNames.length === 0) {
                this.slotNames = this.slots.map(slot => slot.title.toLowerCase());
            }
            if (this.slotName && !this.slotData) {
                const tempSlotName = Utility.removeInBetweenSpaces(this.slotName).trim();
                if (this.slotNames.includes(tempSlotName.toLowerCase())) {
                    return false;
                }
                const isExist = await SlotService.checkSlotExist(tempSlotName);
                return !isExist;
            }
            return true;
        },
        deleteSlot(id) {
            this.$confirm.require({
                message: messages.slotValidation.deleteConfirmation.message,
                header: messages.slotValidation.deleteConfirmation.header,
                accept: async () => {
                    try {
                        await SlotService.delete(id);
                        this.showToaster(messages.slotValidation.slotDeleteSuccess, toasterType.success);
                        this.$emit('closeModal');
                    } catch (err) {
                        this.showToaster(messages.slotValidation.slotDeleteFailed, toasterType.error);
                    }
                },
                acceptClass: 'delete-popup-accept-btn',
                rejectClass: 'p-button-outlined'
            });
        },
        async createSlot() {
            this.disableSaveBtnOnceClicked = true;
            const isFormValid = await this.v$.$validate();
            if (!isFormValid) {
                this.disableSaveBtnOnceClicked = false;
                return;
            }
            this.startDate.setHours(9, 0, 0);
            this.endDate.setHours(18, 0, 0);
            const data = {
                state: this.form.state,
                schedule: {
                    start: this.startDate,
                    end: this.endDate,
                    days: parseInt(this.form.duration)
                },
                memberCount: parseInt(this.form.memberCount),
                autoGenerated: false
            };
            const newData = [];
            data.title = Utility.removeInBetweenSpaces(this.slotName).trim();
            const info = this.slotData ?
                { ...UserActivity.getActivity(userActivities.updated) } : { ...UserActivity.getActivity(userActivities.created),
                    ...UserActivity.getActivity(userActivities.updated) };
            data.info = info;
            if (this.slotCount > 1 && !this.slotData) {
                for (let i = 1; i <= this.slotCount; i++) {
                    data.title = `${ this.$store.getters.userDetails.email.split('@')[0] } ${ this.slotName } - ${ i }`;
                    const title = data.title;
                    const createSlotData = {
                        ...data,
                        info,
                        title
                    };
                    newData.push(createSlotData);
                }
            } else {
                newData.push(data);
            }
            const promises = [];
            let successMsg;
            let errorMsg;
            if (!this.slotData) {
                newData.forEach(x => {
                    promises.push(SlotService.create(x));
                });
                successMsg = messages.slotValidation.slotCretedSuccess;
                errorMsg = messages.slotValidation.slotCreateFailed;
            } else {
                promises.push(SlotService.update(this.form.id, newData[0]));
                successMsg = messages.slotValidation.slotUpdateSuccess;
                errorMsg = messages.slotValidation.slotUpdateFailed;
            }
            try {
                await Promise.all(promises);
                this.showToaster(successMsg, toasterType.success);
                this.$emit('closeModal');
            } catch (err) {
                this.disableSaveBtnOnceClicked = false;
                this.showToaster(errorMsg, toasterType.error);
            }
        }
    },
    emit: ['closeModal']
};
</script>

<style>
</style>
