<template>
    <div class="container container-width">
        <div class="text-end">
            <Button
                class="button-color-lavender-purple mt-2 create-new-button"
                label="CREATE SLOTS"
                @click="showModal = true"
                v-if="isNavigationAllowed(NAVIGATIONCONST.CREATENEWSLOT)"
            />
        </div>
            <DataTable
                class="mt-3"
                :value="slots"
                selectionMode="single"
                dataKey="id"
                :paginator="true"
                :rows="rows"
                :rowsPerPageOptions="rowPerPageOptions"
                :loading="loading"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                responsiveLayout="scroll"
                sortField="title"
                :sortOrder="1"
            >
                <template #header>
                    <Button class="p-button-text" @click="displayFilter = true">
                        <img src="../../../../assets/filter-icon.png" />
                        <span class="ms-2 color-lavender-purple">Filter</span>
                    </Button>
                </template>
                <Column field="title" header="Name" sortable class="table-column-title">
                    <template #body="slotProps">
                        <span
                            class="ms-0"
                            :class="
                                slotProps.data.state == SlotState.InActive
                                    ? 'legend-key gray-background'
                                    : slotProps.data.state == SlotState.Active && slotProps.data.booking == null
                                    ? 'legend-key lavender-purple-background'
                                    : 'legend-key light-green-background'
                            "
                        ></span>
                        <a
                            class="table-first-column"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :title="slotProps.data.title"
                            @click="onRowSelect(slotProps.data)"
                        >
                            {{ slotProps.data.title }}
                        </a>
                    </template>
                </Column>
                <Column field="schedule.start" header="From" sortable>
                    <template #body="slotProps">
                        {{ Utility.formateDate(slotProps.data.schedule.start.seconds) }}
                    </template>
                </Column>
                <Column field="schedule.end" header="Upto" sortable>
                    <template #body="slotProps">
                        {{ Utility.formateDate(slotProps.data.schedule.end.seconds) }}
                    </template>
                </Column>
                <Column field="booking.visitLocationId" header="Booked For">
                    <template #body="slotProps">
                        {{ slotProps.data.booking && slotProps.data.booking.visitLocationInfo ?
                           `${slotProps.data.booking.visitLocationInfo?.buildingId}:
                           ${slotProps.data.booking.visitLocationInfo?.buildingName}` : ''}}
                    </template>
                </Column>
                <Column field="booking.by.name" header="Booked By"></Column>
                <Column field="booking.when" header="Booked On">
                    <template #body="slotProps">
                        {{ slotProps.data.booking?.when ? Utility.formateDate(slotProps.data.booking.when.seconds) : ''}}
                    </template>
                </Column>
                <template #paginatorstart> </template>
            </DataTable>
            <SlotsFilter :displayFilter="displayFilter" @applyFilter="applyFilter" @closeFilter="displayFilter = false" />
            <div v-if="showModal">
                <CreateSlotForm @closeModal="(showModal = false, slotData = null)" :slotData="slotData" :slots="slots" />
            </div>
    </div>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import { FilterMatchMode } from 'primevue/api';
import Utility from '../../../../shared/utility/utility';
import CreateSlotForm from '../create-slots/CreateSlotForm.vue';
import { SlotState } from '../../../../shared/enums/slot-state';
import { toasterTime, rows, rowPerPageOptions, messages, toasterType } from '../../../../shared/constants/constants';
import { NAVIGATIONCONST } from '../../../../shared/constants/navigation-constant';
import { isNavigationAllowed } from '../../../../shared/utils/auth-utils';
import SlotsFilter from './SlotsFilter.vue';
import { SlotService } from '../../../../apis';
export default {
    name: 'AllSlots',
    components: {
        CreateSlotForm,
        DataTable,
        Column,
        Button,
        SlotsFilter
    },
    async created() {
        await this.fetchAllSlots();
    },
    data() {
        return {
            slots: [],
            loading: false,
            rows: rows,
            rowPerPageOptions: rowPerPageOptions,
            showModal: false,
            slotData: null,
            displayFilter: false,
            categories: [],
            filters: {
                state: { value: null, matchMode: FilterMatchMode.EQUALS }
            },
            selectedState: null,
            states: [
                { name: 'Active', code: '0' },
                { name: 'InActive', code: '1' }
            ],
            SlotState: SlotState,
            isNavigationAllowed,
            NAVIGATIONCONST,
            Utility
        };
    },
    methods: {
        showToaster(message, type) {
            this.$toast.add({
                severity: type,
                closable: false,
                detail: message,
                life: toasterTime
            });
        },
        async fetchAllSlots() {
            this.loading = true;
            try {
                this.slots = await SlotService.getSnapshot(this.filters);
            } catch (err) {
                this.showToaster(messages.slotValidation.slotsFetchingFailed, toasterType.error);
            } finally {
                this.loading = false;
            }
        },
        async onRowSelect(event) {
            if (isNavigationAllowed(NAVIGATIONCONST.UPDATESLOT)) {
                const id = event.id;
                try {
                    const slot = await SlotService.getById(id);
                    if (!slot) {
                        this.showToaster(messages.slotValidation.slotFetchingFailed, toasterType.error);
                    } else {
                        this.slotData = slot;
                        this.slotData.id = id;
                        (this.slotData.schedule.start = Utility.convertSecondsToDateTime(this.slotData.schedule.start.seconds)),
                        (this.slotData.schedule.end = Utility.convertSecondsToDateTime(this.slotData.schedule.end.seconds));
                        this.showModal = true;
                    }
                } catch (err) {
                    this.showToaster(messages.slotValidation.slotFetchingFailed, toasterType.error);
                }
            }
        },
        applyFilter(state) {
            this.filters.state.value = state;
            this.fetchAllSlots();
            this.displayFilter = false;
        }
    }
};
</script>

<style>
.filter-dropdown {
    width: 16rem;
}
.margin-left-16 {
    margin-left: 1rem;
}
.margin-top-12 {
    margin-top: 0.75rem;
}
.button-size {
    width: 16.75rem;
    height: 2.625rem;
}
</style>
