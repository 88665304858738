<template>
    <Dialog header="Filter" @hide="$emit('closeFilter')" position="topright" v-model:visible="displayFilter"
    :style="{width: '25.25rem'}" :draggable="false">
        <div class="size14 color-lavender-purple font-weight-500 mb-4" role="button" @click="clearFilters()">CLEAR FILTER</div>
        <div class="text-muted fs-5 font-weight-500 mb-2">State</div>
        <div class="field-checkbox margin-top-12 mb-5">
           <Dropdown class="w-100" v-model="selectedState" :options="SlotState"
           optionLabel="name" optionValue="code" placeholder="Select a State" />
        </div>
        <Button class="filterText apply-button-size justify-content-center button-color-lavender-purple mt-4 w-100"
        @click="applyfilter()">APPLY</Button>
    </Dialog>
</template>

<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import 'primeicons/primeicons.css';
import { SlotState } from '../../../../shared/enums/slot-state';
export default {
    name: 'SlotsFilter',
    components: {
        Button,
        Dialog,
        Dropdown
    },
    props: {
        displayFilter: Boolean
    },
    data() {
        return {
            selectedState: null,
            SlotState: Object.keys(SlotState)
                .map(key => ({ name: String(key), code: SlotState[key] }))
        };
    },
    methods: {
        clearFilters() {
            this.selectedState=null;
            this.$emit('applyFilter', this.selectedState);
        },
        applyfilter() {
            this.$emit('applyFilter', parseInt(this.selectedState));
        }
    }
};
</script>
<style scoped>
</style>
